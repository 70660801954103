@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: ClearSans-Light;
    src: url(../public/Fonts/TTF/ClearSans-Thin.ttf);
}

@font-face {
    font-family: ClearSans;
    src: url(../public/Fonts/TTF/ClearSans-Regular.ttf);
    font-display: block;
}

@font-face {
    font-family: ClearSans-Medium;
    src: url(../public/Fonts/TTF/ClearSans-Medium.ttf);
    font-display: block;
}

@font-face {
    font-family: ClearSans-SemiBold;
    src: url(../public/Fonts/TTF/ClearSans-Light.ttf);
    font-display: block;
}

@font-face {
    font-family: ClearSans-Bold;
    src: url(../public/Fonts/TTF/ClearSans-Bold.ttf);
    font-display: block;
}

@font-face {
    font-family: Noah-Bold;
    src: url(../public/Fonts/Noah-bold.otf);
    font-display: block;
}

a {
    text-decoration: none !important;
}
