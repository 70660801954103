/* Hide the default scrollbar */
.my-custom-scrollbar::-webkit-scrollbar {
    width: 0.5em;
}

.my-custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.my-custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}
