/* Hide the default scrollbar */
.overflow-y-auto::-webkit-scrollbar {
    width: 0.5em;
}

.overflow-y-auto::-webkit-scrollbar-track {
    background-color: transparent;
}

/* Add a gradient effect to the scrollbar thumb */
.overflow-y-auto::-webkit-scrollbar-thumb {
    background-image: linear-gradient(135deg, #000b29, #001b4a);
    border-radius: 10px;
}

/* Show a hover effect on the scrollbar thumb */
.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(135deg, #000b29, #002560);
}

/* Show a rounded glow effect on the scrollbar thumb when active/scrolling */
.overflow-y-auto::-webkit-scrollbar-thumb:active {
    background-image: linear-gradient(135deg, #000b29, #003377);
}

/* Add rounded corners to the top and bottom of the scrollbar thumb when near the edges */
.overflow-y-auto::-webkit-scrollbar-thumb:vertical {
    border-radius: 10px;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.datePicker {
    width: 100% !important;
}

.blur {
    filter: blur(2px);
}
